<template>
       <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->   
            <!-----------blog_detail--------------->
            <section class="blog_detail no_sidebar  pdb_100">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12">
                        <!---------col----------->
                        <div class="featured_image">
                           <div class="image_box">
                              <img src="@/assets/image/resources/Accelerating-post-COVID-recovery-across-Africa.jpg" class="img-fluid" alt="img" />
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-2 col-md-12">
                        <!---------enpty_column----------->
                     </div>
                     <div class="col-lg-9 col-md-12">
                        <!---------col----------->
                        <div class="blog_detail_content">
                           <!-----blog_upper_box------->
                           <div class="blog_detail_upper_box">
                              <h1>Community of practice of universities on disease preparedness and response in Africa</h1>
                             
                            </div>
                           <!-----blog_upper_box-end------>
                           <!-----blog_lower_box------->
                           <div class="blog_detail_lower_box">
                              <p><span class="big_font">T</span><span class="bg_font">T</span>he emergence of the COVID-19 pandemic has further brought to the fore the need for institutions to share expertise, best practices, and work collaboratively across African countries and regions as well as globally to effectively respond to epidemic challenges.
                              </p>
                              <p>The project is establishing a community of practice (CoP) of African institutions focused on sharing evidence, 
                              expertise, peer learning and strengthening capacities for COVID-19 control and the broader disease preparedness and response.</p>
                              <p>The CoP will discuss several themes in line with the outcomes of the COVID-19 assessment project including testing and surveillance, health systems resilience, non-pharmaceutical interventions, gender and socio-economic vulnerabilities, vaccine preparedness and epidemic control among others.</p>
                             <p>The CoP will contribute to the development of the capacity of African institutions to successfully prepare, analyze and respond to disease epidemics. Information on joining the CoP will be availed soon.</p>
                             
                           </div>
                  
                         
                          
                        </div>
                        <!---------col-end---------->
                     </div>
                  
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------blog_detail-end-------------->  
          
</template>